// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);


// Navigation
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
    if (window.innerWidth < 992) {
      document.body.classList.toggle('body--activenav');
    }
  });
})

// Finder

document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__findertoggle').addEventListener('click', () => {
    document.body.classList.toggle('body--activefinder');
  });
})

// Check if scrolled to change styling for fixed elements

window.addEventListener('scroll', () => {
  if(window.scrollY > 0) {
    document.querySelector('body').classList.add('body--scrolled')
  } else {
    document.querySelector('body').classList.remove('body--scrolled')
  }
})


// Offcanvas Behaviour

let setMenuLevel = (level) => {
  let menu = document.querySelector('.headerbar__navigation > ul');

  for (let clickedItem of document.querySelectorAll('.headerbar__navigation li.parent.clicked')) {
    clickedItem.classList.remove('clicked')
  }

  menu.setAttribute('class', menu.getAttribute('class').replace(/(nav--level\d)/, ''))
  menu.classList.add('nav--level' + level)

}

// Main menu behavior
for (let menuItem of document.querySelectorAll('.headerbar__navigation li.parent > a, .headerbar__navigation li.parent > span')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      const item = e.target;

      // Prevent link opening
      e.preventDefault()

      const parentClass = item.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) + 1)

      item.parentElement.classList.add('clicked')

      if (item.parentElement.parentElement.parentElement.classList.contains('parent')) {
        item.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}

for (let menuItem of document.querySelectorAll('.headerbar__navigation button.mainnav__backlink')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      e.preventDefault()

      const parentClass = e.target.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) - 1)

      e.target.parentElement.parentElement.parentElement.classList.add('clicked')

      if (e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('parent')) {
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}

// Image-Sizing for Content-Pages

let scaleArticleImage = (articleImage) => {

  let imageWidth = 1352 * (2 / 3);
  let imageOffset = (window.innerWidth - 1352) / 2;
  let scaling = ((imageWidth + imageOffset) / imageWidth) * 100

  articleImage.style.width = scaling + '%';
}



document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.com-content-article__intro-image .item-image, .com-content-category-blog__pageheader-image img')) {
    if (window.innerWidth >= 1352) {
      scaleArticleImage(document.querySelector('.com-content-article__intro-image, .com-content-category-blog__pageheader-image'));
    }
  }
})
window.addEventListener('resize', () => {
  if (document.querySelector('.com-content-article__intro-image .item-image, .com-content-category-blog__pageheader-image img')) {
    if (window.innerWidth >= 1352) {
      scaleArticleImage(document.querySelector('.com-content-article__intro-image, .com-content-category-blog__pageheader-image'));
    }
  }
})

// Footer-Sections
document.addEventListener('DOMContentLoaded', () => {
  for (let footerContentSection of document.querySelectorAll('.footer__contentsection')) {
    footerContentSection.querySelector('.footer__contentsection-headline').addEventListener('click', () => {
      if (window.innerWidth <= 767) {
        footerContentSection.classList.toggle('expanded');
      }
    })
  }
})

// Set Toolbarheight on Pageload / Resize
let updateToolbarHeight  = () => {
  document.documentElement.style.setProperty('--toolbarheight', document.querySelector('.toolbar').clientHeight + "px");
}
document.addEventListener('DOMContentLoaded', updateToolbarHeight);
window.addEventListener('resize', updateToolbarHeight);